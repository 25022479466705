import React from "react";

export default function Loading() {
  return (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }}>
        <h1 style={{
            color: 'white'
        }}>Yükleniyor...</h1>
        <img 
            src="/loading.svg"
            className="loading"
            alt="Loading..."
            style={{
                width: "60px",
                height: "60px",
                margin: "auto",
                display: "block"
            }}
        />
    </div>
  );
}
