import React from "react";

import { useEffect, useState } from "react";
import PaymentCard from "./components/PaymentCard"
import { getParamDataFromUrl } from "./utils/getParamDataFromUrl";
import { api } from "./utils/api";
import Loading from "./components/Loading";
import moment from "moment-timezone";

export const WAITING_TIME = 60 * 15;

const getExpireDateDiff = (time) => {
    const expired = moment(time).tz("UTC");
    const now = moment().tz("UTC");
    const diff = expired.diff(now, "seconds");
    if (diff > 0) {
      return diff;
    } else {
      return 0;
    }
  };

const App = () => {
    const [transactionId, setTransactionId] = useState(null);
    const [transactionMethod, setTransactionMethod] = useState(null);

    const [iframeId, setIframeId] = useState(undefined);

    const [payload, setPayload] = useState({
        id: 0,
        playerFullName: "Ulaş Kökkılıç",
        ibanName: "Provider Full Name",
        iban: "TR1234567890123456",
        amount: 1234.35,
        expiredDate: new Date(),
        status: 0,
    });
    const [time, setTime] = useState(WAITING_TIME);

    const [paymentStatus, setPaymentStatus] = useState(0);
    const [isPending, setPendingStatus] = useState(true);
    const [pendingCorrupted, setPendingCorrupted] = useState(null);

    const [isIntervalTime, setIntervalTime] = useState(null);
    const [isIntervalControl, setIntervalControl] = useState(null);

    const [isPressedPaymentButton, setIsPressedPaymentButton] = useState(false);
      
    const checkParams = () => {
        const paramId = getParamDataFromUrl();
        const paramMethod = getParamDataFromUrl(window.location, 'method');

        if (paramId && paramMethod) {
            setTransactionId(paramId);
            setTransactionMethod(paramMethod);
        } else {
            throw new Error('Invalid transaction ID or method');
        }
    };

    const getTransactionInfo = async () => {
        const response = await api.post('/robot/info', {id: transactionId, method: transactionMethod});
        setPendingStatus(false)
        
        if( response.status !== 200) {
            setPendingCorrupted(response.data.error)
            return;
        };
        const data = response.data;
        
        if(transactionMethod === 'havale' || transactionMethod === 'crypto'){
            setPaymentStatus(data.data.status);
            setPayload({
                id: 1,
                playerFullName: data.data.playerFullName,
                ibanName: data?.data?.iban?.name ?? undefined,
                iban: data?.data?.iban?.iban ?? undefined,
                amount: data.data.amount,
                expiredDate: data.data.expiredTime,
                status: data.data.status,
                cryptoType: data.data.cryptoType ?? undefined,
                token: data.data.token ?? undefined,
            });
        } else {
            setIframeId(transactionId)
        }
    }

    useEffect(() => {
        checkParams();
        document.title = 'Kaptanpay Ödeme Sistemleri';
    }, [])

    useEffect(() => {
        if(transactionId && transactionMethod) {
            getTransactionInfo();
        }
    }, [transactionId, transactionMethod])

    useEffect(() => {
        var interval = null
        if (transactionId && !interval) {
            getTransactionInfo();
          interval = setInterval(() => {
            setTime((prev) => {
              if (prev === 0) {
                clearInterval(interval);
                getTransactionInfo();
                return 0;
              }
              return prev - 1;
            });
          }, 1000);
          setIntervalTime(interval);
        }
      }, [transactionId]);
    
      useEffect(() => {
        var controlInterval = null;
        if (isPressedPaymentButton && !controlInterval) {
          controlInterval = setInterval(() => {
            getTransactionInfo();
          }, 5000);
          setIntervalControl(controlInterval);
        }
      }, [isPressedPaymentButton]);
    
      useEffect(() => {
        if(paymentStatus === 1 || paymentStatus === 2) {
          clearInterval(isIntervalTime);
          clearInterval(isIntervalControl);
        }
      }, [paymentStatus])

      useEffect(() => {
        if (payload) {
          const dateDiff = getExpireDateDiff(payload.expiredDate);
          console.log(dateDiff);
          setTime(dateDiff);
        }
      }, [payload]);

      console.log(iframeId);
    return (
        <>
            {
                iframeId ? (
                    <iframe 
                        src={`https://screen.vevopay.com/iframe?ft=${iframeId}`} 
                        title="Payment Iframe" 
                        style={{
                            width: '100dvw',
                            height: '100dvh',
                        }} 
                    />
                ) : (
                    <div
                        className="wrapper"
                        style={{
                        backgroundColor:
                            paymentStatus === 0 && transactionId
                            ? "#329EF1"
                            : paymentStatus === 1 && transactionId
                            ? "#20c997"
                            : paymentStatus === 2 && transactionId
                            ? "#A71C33"
                            : "#A71C33",
                        }}
                    >
                        {
                            isPending ? (
                                <Loading />
                            ) : (
                                <PaymentCard
                                    time={time}
                                    transactionId={transactionId}
                                    transactionMethod={transactionMethod}
                                    paymentStatus={paymentStatus}
                                    payload={payload}
                                    setIsPressedPaymentButton={setIsPressedPaymentButton}
                                    isPressedPaymentButton={isPressedPaymentButton}
                                />
                            )
                        }
                    </div>
                )
            }
        </>
    )
}

export default App;
