import { formatAmount } from "../utils/format";
import React from "react";
import { WAITING_TIME } from "../App"

export default function PaymentCard({payload, transactionId, paymentStatus, time = 0, setIsPressedPaymentButton, isPressedPaymentButton}) {
  const statusInfo = [
    {
      status: 0,
      title: "Ödeme Bekleniyor...",
    },
    {
      status: 1,
      title: "Ödeme Başarılı!",
    },
    {
      status: 2,
      title: "Ödeme Başarısız!",
    },
  ];  

  return (
    <div className="container">
      <div
          className="payment_card"
          style={
            paymentStatus === 1 || paymentStatus === 2 || paymentStatus === 3
              ? {
                  animation: 0,
                }
              : null
          }
        >
          <div className="pc_header">
            <div className="pc_header_title">
              {transactionId
                ? paymentStatus === 1
                  ? "Ödeme Onaylandı!"
                  : paymentStatus === 2
                  ? "Ödeme Başarısız!"
                  : paymentStatus === 3
                  ? "Zaman Aşımı!"
                  : "Ödeme Bekleniyor..."
                : "Ödeme Talep Formu"}
            </div>
          </div>
          <div className="pc_body">
            {transactionId && (
              <>
                <span
                  style={{
                    color: "#5F5F5F",
                    fontSize: "1.12rem",
                    fontWeight: 700,
                    padding: 16,
                  }}
                >
                  Sn. {payload.playerFullName},
                </span>
                <div className="pc_body_row" style={payload.cryptoType ? {
                  flexDirection: "column",
                  alignItems: "start"
                } : {}}>
                  <div className="pc_body_row_title" style={{textWrap: 'nowrap'}}>
                    Ödeme Yapılacak {payload.cryptoType ? 'Cüzdan Numarası' : 'IBAN'}:
                  </div>
                  <div className="pc_body_row_content">
                    <div style={{ overflow: "hidden"}}>
                      {payload.cryptoType ? payload.token : payload.iban}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "4px",
                        color: "white",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginLeft: "8px",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(payload.cryptoType ? payload.cryptoType : payload.iban);
                      }}
                    >
                      <img src="/copy-check.svg" alt="copy-button" />
                    </div>
                  </div>
                </div>
                <div className="pc_body_row">
                  <div className="pc_body_row_title">
                    Ödeme Yapılacak {payload.cryptoType ? "Kripto Türü" : "IBAN Sahibi"}:
                  </div>
                  <div className="pc_body_row_content">
                    {payload.cryptoType ? payload.cryptoType.split('.')[0] : payload.ibanName}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "4px",
                        color: "white",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginLeft: "8px",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          payload.providerFullName
                        );
                      }}
                    >
                      <img src="/copy-check.svg" alt="copy-button" />
                    </div>
                  </div>
                </div>
                <div className="pc_body_row">
                  <div className="pc_body_row_title">Ödenecek Tutar:</div>
                  <div className="pc_body_row_content">
                    {formatAmount(payload.amount)}{payload.cryptoType ? payload.cryptoType.split('.')[0] : '₺'}
                  </div>
                </div>
              </>
            )}
            {paymentStatus === 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "16px",
                  gap: "4px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#4ECB71",
                }}
              >
                <img
                  src="/tick-circle.svg"
                  alt=""
                  style={{
                    width: "64px",
                    height: "64px",
                  }}
                />
                Ödeme Başarılı!
              </div>
            )}
            {(paymentStatus === 2 || paymentStatus === 3 || !transactionId) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "16px",
                  gap: "4px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#F24E1E",
                }}
              >
                <img
                  src="/x-circle.svg"
                  alt=""
                  style={{
                    width: "64px",
                    height: "64px",
                  }}
                />
                Hata: {!transactionId && "Geçersiz ID!"}
              </div>
            )}

            {paymentStatus === 0 && transactionId && (
              <>
                <div
                  style={{
                    position: "absolute",
                    bottom: "8px",
                    width: "100%",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <span
                      style={{
                        position: "absolute",
                        textWrap: "nowrap",
                        left: "50%",
                        transform: "translateX(-50%)",
                        fontSize: "12px",
                        top: "-20px",
                      }}
                    >
                      {time > 0 &&
                        `${time} saniye içinde ödeme yapmalısınız.`}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: "8px",
                    width: `${(time / WAITING_TIME) * 100}%`,
                    bottom: 0,
                    transition: "1s all",
                    background:
                      "linear-gradient(90deg, #FFC10783 0%, #FFC1077D 50%, #FFC107 100%)",
                  }}
                ></div>
              </>
            )}
          </div>
          <div className="pc_footer">
            <button
              className="pc_apply_button"
              onClick={() => {
                setIsPressedPaymentButton(true);
              }}
              disabled={isPressedPaymentButton}
            >
              Ödeme Yaptım
            </button>
          </div>
        </div>
    </div>
  );
}
